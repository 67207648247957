<template>
  <b-input
    :type="tglMasukInputType"
    :key="tglMasukInputType"
    v-model="computedValue"
    placeholder="dd/mm/yyyy"
    :required="required"
    icon="calendar-day"
    @focus="setDatePicker"
  ></b-input>
</template>


<script>
import { compareAsc, isValid, format, parse } from "date-fns";

const formatMap = {
  text: "dd/MM/yyyy",
  date: "yyyy-MM-dd"
};

export default {
  name: "DatePicker",
  props: { value: String, required: Boolean },
  data() {
    return {
      valueData: this.dateParser(this.value, "text"), //this.value,
      tglMasukInputType: "text",
      // firstBlur: true
    };
  },
  computed: {
    computedValue: {
      get() {
        // debugger;
        const value = this.dateFormatter(
          this.valueData,
          this.tglMasukInputType
        );
        return value;
      },
      set(value) {
        const dateObj = this.dateParser(value, "date");
        let textValue;
        if (isValid(dateObj)) {
          textValue = this.dateFormatter(dateObj, "text");
          this.valueData = dateObj;
        } else {
          textValue = null;
          this.valueData = null;
        }
        this.$emit("input", textValue);
      }
    },
    isDateInput() {
      return this.tglMasukInputType === "date";
    },
  },
  methods: {
    dateFormatter(date, strFormat) {
      return date ? format(date, formatMap[strFormat]) : null;
    },
    dateParser(dateStr, strFormat) {
      const dateObj = parse(dateStr, formatMap[strFormat], new Date());
      const value = isValid(dateObj) ? dateObj : null;
      return value;
    },
    setDatePicker() {
      if (!this.isDateInput) {
        Promise.resolve([])
          .then(() => {
            this.tglMasukInputType = "date";
          })
          .then(() => {
            this.$el.getElementsByTagName("input")[0].focus();
          })
          .then(() => {
            // untuk mentrigger mobile date picker
            this.$el.getElementsByTagName("input")[0].click();
          });
      }
    }
  },
  watch: {
    // When v-model is changed: update internal value
    value(value) {
      // debugger;
      // value dari parent selalu berupa string dengan format "text" "dd/MM/yyyy"
      const dateObj = this.dateParser(value, "text");
      if (isValid(dateObj)) {
        if (compareAsc(this.valueData, dateObj) !== 0) {
          this.valueData = dateObj;
        }
      } else {
        this.valueData = null;
      }
    }
  }
};
</script>
